

















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Wip extends Vue {
  FPS = 2;

  DURATION = 300;

  CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  // CHARACTERS = 'アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン';
  // CHARACTERS = 'あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん';
  // CHARACTERS = 'ダウンロードする';

  // TEXT = 'ダウンロードする';
  TEXT = 'DOWNLOAD';

  currentText = this.TEXT;

  DELAY = Math.floor(100 / this.FPS);

  FRAME_COUNT = Math.floor(this.DURATION / 100) * this.FPS

  frameIndex = 0;

  timeoutId = 0;

  resetText(): void {
    if (this.timeoutId !== undefined) clearTimeout(this.timeoutId);
    this.frameIndex = 0;
    this.currentText = this.TEXT;
  }

  setRandomText(): void {
    const text = Array.from({ length: this.TEXT.length }).map(
      () => this.CHARACTERS[Math.floor(Math.random() * this.CHARACTERS.length)],
    );
    this.currentText = text.join('');
  }

  animate(): void {
    if (this.frameIndex >= this.FRAME_COUNT) {
      this.resetText();
    } else {
      this.frameIndex += 1;
      this.setRandomText();
      this.timeoutId = setTimeout(this.animate, this.DELAY);
    }
  }
}
