import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '@/App.vue';
import Wip from '@/components/WIP.vue';

Vue.config.productionTip = false;

// const Default = { template: '' };

const routes: any = [
  { path: '', component: Wip },
];

const router = new VueRouter({
  mode: 'history',
  routes, // short for `routes: routes`
});

Vue.use(VueRouter);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
