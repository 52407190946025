import { render, staticRenderFns } from "./WIP.vue?vue&type=template&id=6bf403ae&scoped=true&"
import script from "./WIP.vue?vue&type=script&lang=ts&"
export * from "./WIP.vue?vue&type=script&lang=ts&"
import style0 from "@/scss/tea.css?vue&type=style&index=0&lang=css&"
import style1 from "@/scss/download.css?vue&type=style&index=1&lang=css&"
import style2 from "./WIP.vue?vue&type=style&index=2&id=6bf403ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf403ae",
  null
  
)

export default component.exports